<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">meeting attendance</div>
      </v-row>
      <v-row class="">
        <div class="">
          View County Assembly Member meeting attendance using the filters below
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>

        <v-autocomplete
          prepend-icon="mdi-account-group"
          label="Meeting Body"
          :items="meetingBodyItems"
          outlined
        ></v-autocomplete>
        <v-autocomplete
          prepend-icon="mdi-account-group"
          label="Member"
          :items="councilMemberItems"
          outlined
        ></v-autocomplete>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date black--text"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      meetingBodyItems: [],
      councilMemberItems: [],
      headers: [
        {
          text: "meeting body",
          value: "name",
          class: "text-uppercase black--text",
        },
        {
          text: "meeting date",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "meeting time",
          value: "time",
          class: "text-uppercase black--text",
        },
        {
          text: "member",
          value: "member",
          class: "text-uppercase black--text",
        },
        {
          text: "attendance",
          value: "attendance",
          class: "text-uppercase black--text",
        },
        {
          text: "time in",
          value: "time",
          class: "text-uppercase black--text",
        },
        {
          text: "time out",
          value: "time",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          name: "Commitee of the whole",
          date: "Dec 10, 2021",
          time: "7:30 AM",
          member: "johnson",
          attendance: "present",
        },
        {
          name: "Budget Commitee",
          date: "Dec 12, 2021",
          time: "4:30 AM",
          type: "regular",
          member: "johnson",
          attendance: "present",
        },
        {
          name: "Public Health Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          member: "johnson",
          attendance: "present",
        },
        {
          name: "Public Safety Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          member: "johnson",
          attendance: "present",
        },
        {
          name: "Public Transport Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          member: "johnson",
          attendance: "present",
        },
        {
          name: "Budget Commitee",
          date: "Dec 13, 2021",
          time: "10:30 AM",
          member: "johnson",
          attendance: "present",
        },
      ],
    };
  },
};
</script>

<style></style>
